<template>
    <div class="fixed left-0 bottom-0 bg-white w-full">
        <div class="flex w-full py-2">
            <div class="flex-1 flex text-center mb-0 mx-auto relative" v-for="(item, n) in menu" :key="n">
                <b-tag v-if="item.badge" :type="item.badge.type" rounded class="absolute" style="top: 0;left:50%">{{item.badge.value}}</b-tag>
                <b-icon
                    v-if="!item.to"
                    :icon="item.icon"
                    :custom-size="iconSize">
                </b-icon>
                <router-link v-else :to="item.to" replace class="text-gray-800 block w-full text-center flex-1 flex justify-center items-center" @click.native.prevent="checkLoginAndNavigate(item.to)">
                    <b-icon
                        v-if="item.icon"
                        :icon="item.icon"
                        :custom-size="iconSize">
                    </b-icon>
                    <img v-if="item.img" :src="item.img" width="32px" height="32px" />
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['items'],
    data () {
        return {
            iconSize: 'mdi-24px'
        }
    },
    computed: {
      menu () {
        return this.items || [
                {
                    name: 'Home',
                    icon: 'home'
                },
                {
                    name: 'Chat',
                    icon: 'chat-processing'
                },
                {
                    name: 'Notification',
                    icon: 'bell'
                },
                {
                    name: 'User',
                    icon: 'account'
                }
            ]
      }
    },
    methods: {
        checkLoginAndNavigate (to) {
            if (this.user.IsLogin || to === '/user/dashboard') {
                this.$router.push(to)
            } else {
                this.$router.push('/login')
            }
        }
    }
}
</script>
